import React, { useCallback, useState } from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';

import { useLanguage } from 'app/language-context';

import { getInfoLinks } from 'constants/header';

import { ReactComponent as UserButton } from 'assets/images/svg/user.svg';
import heartImage from 'assets/images/svg/pic_heart.svg';
import ruFlagImage from 'assets/images/flags/ru.png';
import kzFlagImage from 'assets/images/flags/kz.jpg';
import enFlagImage from 'assets/images/flags/en.png';

import Logo from 'assets/images/LogoMain';
import { ReactComponent as LockButton } from 'assets/images/svg/button_lock.svg';

import MobileMenu from './mobile-header-menu/MobileMenu';

import './Header.scss';

const langImages = {
  KAZ: kzFlagImage,
  RU: ruFlagImage,
  EN: enFlagImage,
};

const Header = ({ user, location }) => {
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);
  const { lang, changeLang, LANGUAGES, currentLang } = useLanguage();

  const getNestedMenu = useCallback(
    (links) => (
      <Menu>
        {links.map((link, index) => (
          <Menu.Item key={index}>
            <Link to={link.routerParams} className="Header__menu-item__link">
              {link.title}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [],
  );

  const getLangMenu = useCallback(() => {
    const otherLanguages = Object.values(LANGUAGES).filter((l) => l !== currentLang);
    return (
      <Menu>
        {otherLanguages.map((langValue) => (
          <Menu.Item key={langValue}>
            <button
              type="button"
              className="button button--white"
              onClick={() => changeLang(langValue)}
            >
              <div className="Header__lang__flag">
                <img src={langImages[langValue]} alt="flag" />
              </div>
              {lang.HEADER.LANGUAGES[langValue]}
            </button>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [lang, currentLang, changeLang, LANGUAGES]);

  const toggleMobileMenu = useCallback(
    () => setMobileMenuVisibility(!isMobileMenuVisible),
    [isMobileMenuVisible],
  );

  const setInvisible = useCallback(() => setMobileMenuVisibility(false), []);

  const getLanguageClass = (lang) => {
    switch (lang) {
      case 'KAZ':
        return 'kaz';
      case 'RU':
        return 'ru';
      case 'EN':
        return 'en';
      default:
        return '';
    }
  };

  const isAuthenticated = !!localStorage.getItem('refresh_token');

  const headerClasses = `Header d-flex align-items-center ${getLanguageClass(
    currentLang,
  )} ${isAuthenticated ? 'no-help-button' : ''}`;

  return (
    <section className={headerClasses}>
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="d-block d-xl-none col-2">
            <div
              className={`toggle-menu ${isMobileMenuVisible ? 'on' : ''}`}
              onClick={toggleMobileMenu}
            >
              <span></span>
            </div>
          </div>
          <div className="d-none col-xl-5 d-xl-flex align-items-center">
            {getInfoLinks(lang).map((link, index) => {
              return (
                <div className="Header__info__link-wrapper" key={index}>
                  <Dropdown
                    overlay={() => getNestedMenu(link.children)}
                    overlayClassName="Header__dropdown-overlay"
                  >
                    <Link
                      to={link.routerParams}
                      className={`Header__info__link ${
                        link.routerParams.pathname === location.pathname ? 'active' : ''
                      }`}
                    >
                      {link.title}
                    </Link>
                  </Dropdown>
                </div>
              );
            })}
          </div>
          <div className="col-8 col-xl-2 text-center d-md-flex align-items-center justify-content-center">
            <div className="logo-top text-center d-flex align-items-center justify-content-center">
              <Link to="/" onClick={setInvisible}>
                <Logo className="niyet-logo" alt="logo-niyet" />
              </Link>
            </div>
          </div>
          <div className="d-none d-xl-flex justify-content-end col-md-5 d-grid align-items-center">
            <div className="d-flex justify-content-end">
              {!isAuthenticated && (
                <Link className="button button--red outlined" to="/help">
                  <img src={heartImage} alt="heart" className="Header__btn__heart" />
                  {lang.HEADER.HELP}
                </Link>
              )}
              {user ? (
                <Link to="/profile/beneficiaries">
                  <button type="button" className={`button ${getLanguageClass(currentLang)}`}>
                    {lang.HEADER.CABINET}
                  </button>
                </Link>
              ) : (
                <Link to="/auth/login">
                  <button className={`button ${getLanguageClass(currentLang)}`}>
                    {lang.HEADER.LOGIN}
                  </button>
                </Link>
              )}

              <Dropdown overlay={getLangMenu}>
                <button type="button" className="button button--white">
                  <div className="Header__lang__flag">
                    <img src={langImages[currentLang]} alt="flag" />
                  </div>
                  {lang.HEADER.LANGUAGES[currentLang]}
                </button>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex d-xl-none justify-content-center align-items-center col-2">
            {user ? (
              <Link
                to="/profile/beneficiaries"
                onClick={setInvisible}
                className="d-flex justify-content-center align-items-center"
              >
                <UserButton className="fill-green Header__lock-btn" />
              </Link>
            ) : (
              <Link
                to="/auth/login"
                onClick={setInvisible}
                className="d-flex justify-content-center align-items-center"
              >
                <LockButton className="fill-green Header__lock-btn" />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="d-block d-xl-none">
        <MobileMenu
          isVisible={isMobileMenuVisible}
          setInvisible={setInvisible}
          user={user}
        />
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, {})(withRouter(Header));
